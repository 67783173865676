.select {
  height: 50px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  padding: 15px;
}

.options {
  box-shadow: 0px 0px 10px rgba(121, 121, 121, 0.1);
  border-radius: 5px;
  max-height: 240px;
  overflow-y: auto;
}

.options-top {
  top: -225px;
}

.options li {
  cursor: pointer;
  padding: 12px 15px;
}

/* width */

.options::-webkit-scrollbar {
  width: 5px;
  padding-right: 6px;
}

/* Track */

.options::-webkit-scrollbar-track {
  background: #f4f5fa;
  border-radius: 10px;
}

/* Handle */

.options::-webkit-scrollbar-thumb {
  background: #ebebeb;
  border-radius: 10px;
}

.select .text-multi-select {
  width: 86%;
}

.checkbox-mul-selected.MuiCheckbox-colorSecondary.Mui-checked {
  color: #ff7500;
}

.checkbox-mul-selected.MuiCheckbox-root {
  padding: 0px 9px 0px 0px !important;
}

.checkbox-mul-selected.MuiCheckbox-root:hover {
  background-color: transparent !important;
}
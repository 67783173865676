.role-pill {
  height: 40px;
  min-width: 170px;
  border-radius: 20px;
}

.role-pill-background {
  background-color: rgba(35, 31, 32);
}

.role-pill-background-disabled {
  background-color: rgba(35, 31, 32, 0.3);
}

.line-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-break: break-word;
}

.phone-country-select .select {
  height: 50px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
  background-color: #f4f5fa;
}

.phone-country-select .options {
  border-radius: 10px;
  max-height: 240px;
  overflow-y: auto;
  box-shadow: 0px 0px 10px #7979791a;
  top: 50px;
}

.phone-country-select .options .li {
  /* cursor: none !important; */
  padding: 12px 15px;
}

.phone-country-select + p {
  margin-top: 5px;
  color: #ff0000;
}

.select-notify-category .select {
    height: 25px;
    border: none;
    padding: 0;
    border-radius: 0;
    color: #FF7500;
    font-weight: 600;
    font: Kanit;
}

.select-notify-category #arrow_down_black_24dp {
    fill: #FF7500;
}

.select-notify-category .options {
    left: -14px;
    font-family: 'Kanit';
    color: #000000;
}

.mask-all {
    color: #FF7500;
    font-weight: 600;
    min-width: 180px;
    text-align: right;
}

.notify-container {
    padding-top: 15px;
    padding-bottom: 10px;
    padding-right: 27px;
    border-bottom: 2px solid #EBEBEB;
}

.notify-details {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    word-break: break-word;
    width: 100%;
    color: #BCBCBC;
    text-align: left;
}

.container-content {
    overflow-y: auto;
}

.container-content::-webkit-scrollbar {
    display: none;
}

.unread-notify {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #FF7500;
}

.txt-no-data-notify {
    color: #231f20;
    font-size: 16px;
}
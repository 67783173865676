.user-card .user-letter {
  min-width: 62px;
  height: 62px;
  font-size: 24px;
  background-color: #ffe2cb;
  border: 3px solid #fff1e5;
}

.user-card .avatar {
  width: 62px;
  height: 62px;
  object-fit: cover;
}

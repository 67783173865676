.status-dropdown {
  width: 110px;
  height: 25px;
  font-size: 1rem;
}

.status-select {
  border-radius: 15px;
}

.status-active {
  background-color: #8dc900;
}

.status-active svg {
  position: absolute;
}

.status-terminate {
  background-color: #231f20;
}

.status-inactive {
  background-color: #f3b916;
}

.status-inactive svg {
  position: absolute;
}

.status-select svg {
  fill: #fff;
}

.status-options {
  box-shadow: 0px 0px 10px rgba(121, 121, 121, 0.1);
  border-radius: 5px;
  max-height: 240px;
  overflow-y: auto;
}

.status-options li {
  padding: 10px;
}

.status-select:hover {
  opacity: 0.8;
}
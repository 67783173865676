.date-picker .date-picker-border {
  border: 1px solid #ebebeb;
  border-radius: 5px;
}

.date-picker .date-picker-container {
  border-radius: 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 10px #0000001a;
}

.date-picker .selected {
  background-color: #ff7500 !important;
}

.date-picker .day {
  color: #ff7500 !important;
}

.date-picker .day span > ::after {
  background-color: #ff7500 !important;
}

.date-picker .week-day {
  text-transform: uppercase !important;
  color: #231f20 !important;
  background-color: #f4f5fa !important;
  height: 44px !important;
  width: 44px !important;
  margin-right: 1px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.date-picker .week-day:last-child {
  color: #ff7500 !important;
}

.date-picker .week-day:nth-last-child(2) {
  color: #ff7500 !important;
}

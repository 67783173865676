svg.loader {
  animation: 1.5s linear infinite spin-animation;
  max-width: 100px;
}

@keyframes spin-animation {
  0% {
    transform: rotateZ(0deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

circle.circle-animation {
  animation: 1.4s ease-in-out infinite both circle-animation;
  display: block;
  fill: transparent;
  stroke: currentColor;
  stroke-linecap: round;
  stroke-dasharray: 185;
  stroke-dashoffset: 0px;
  /* stroke-width: 2px; */
  transform-origin: 50% 50%;
}

@keyframes circle-animation {
  0%,
  25% {
    stroke-dashoffset: 185;
    transform: rotate(0);
  }

  50%,
  75% {
    stroke-dashoffset: 75;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 185;
    transform: rotate(360deg);
  }
}

/* :root {
  --loading-color: #ed1c24;
} */

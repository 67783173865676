.collapsible-block {
  box-shadow: 0px 0px 10px #7979791a;
  border-radius: 10px;
}

.collapsible-block .collapsible-block-header .chevron-up {
  fill: #ff7500;
}

.collapsible-block .collapsible-block-content {
  border-top: 1px solid #ebebeb;
}
.MuiIconButton-label {
  width: auto !important;
}

.country-select .select {
  height: 50px;
  border: 1px solid #ebebeb;
  border-radius: 5px;
}

.country-select .options {
  border-radius: 10px;
  max-height: 240px;
  overflow-y: auto;
  box-shadow: 0px 0px 10px #7979791a;
  top: 50px;
}

.country-select .options .li {
  cursor: pointer;
  padding: 12px 15px;
}
